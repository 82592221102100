<!-- Preloader -->
<div class="preloader">
  <div class="preloader__wrap">
    <div class="circle-pulse">
      <div class="circle-pulse__1"></div>
      <div class="circle-pulse__2"></div>
    </div>
    <div class="preloader__progress"><span></span></div>
  </div>
</div>

<!-- Scroll Indicator -->
<div class="scroll-line"></div>

<main class="main">
  <div class="container gutter-top">
    <div class="row sticky-parent">
      <!-- Sidebar -->
      <aside class="col-12 col-md-12 col-xl-3">
        <div class="sidebar box shadow pb-0 sticky-column">
          <svg class="avatar avatar--180" viewbox="0 0 188 188">
            <g class="avatar__box">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <div class="text-center">
            <h3 class="title title--h3 sidebar__user-name"><span class="weight--500">Lihong</span> KHUT</h3>
            <div class="badge badge--light">Tech Team Leader</div>

            <!-- Social -->
            <div class="social">
              <a class="social__link" href="http://www.fb.com/kh.lihong/"><i class="font-icon icon-facebook"></i></a>
              <a class="social__link" href="https://twitter.com/khutlihong"><i class="font-icon icon-twitter"></i></a>
              <a class="social__link" href="https://kh.linkedin.com/in/khut-lihong168"><i
                  class="font-icon icon-linkedin2"></i></a>
            </div>
          </div>

          <div class="sidebar__info box-inner box-inner--rounded">
            <ul class="contacts-block">
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Birthday">
                <i class="font-icon icon-calendar"></i>April 02, 1990
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Address">
                <i class="font-icon icon-location"></i>Krong Siem Reap,
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="E-mail">
                <a href="mailto:me@khutlihong.dev"><i class="font-icon icon-envelope"></i>me@khutlihong.dev</a>
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Phone">
                <i class="font-icon icon-phone"></i>
                085 55 07 07 (Cellcard) <br>
                086 55 07 07 (Smart)
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Messenger">
                <a href="https://m.me/kh.lihong"><i class="font-icon icon-facebook"></i>KHUT Lihong</a>
              </li>
            </ul>
            <i class="fa-brands fa-facebook-messenger"></i>

            <a class="btn" href="#"><i class="font-icon icon-download"></i> Download CV</a>
          </div>
        </div>
      </aside>

      <!-- Content -->
      <div class="col-12 col-md-12 col-xl-9">
        <div class="box shadow pb-0">


          <!-- Post -->
          <div class="pb-3">
            <header class="header-post">
              <div class="header-post__date">Sep 15, 2019</div>
              <h1 class="title title--h1">Design Conferences in 2019</h1>
              <div class="caption-post">
                <p>Above all, think of life as a prototype. We can conduct experiments, make discoveries, and change our
                  perspectives. We can look for opportunities to turn processes into projects that have tangible
                  outcomes.</p>
              </div>
              <div class="header-post__image-wrap">
                <img class="cover lazyload" src="assets/img/image_02.jpg" alt="">
              </div>
            </header>
            <div class="caption-post">
              <p>Above all, think of life as a prototype. We can conduct experiments, make discoveries, and change our
                perspectives. We can look for opportunities to turn processes into projects that have tangible outcomes.
                We can learn how to take joy in the things we create whether they take the form of a fleeting experience
                or an heirloom that will last for generations. We can learn that reward comes in creation and
                re-creation.</p>
              <p>It is not enough that we build products that function, that are understandable and usable, we also need
                to build products that bring joy and excitement, pleasure and fun, and, yes, beauty to people’s lives.
                Creativity is to discover a question that has never been asked.</p>
            </div>
            <div class="gallery-post">
              <img class="gallery-post__item cover lazyload" src="assets/img/image_06.jpg" data-zoom="" alt="">
              <img class="gallery-post__item cover lazyload" src="assets/img/image_08.jpg" data-zoom="" alt="">
              <div class="gallery-post__caption">Work by <a href="#">Colin Adams</a>, <a href="#">Mark Craig</a></div>
            </div>
            <div class="caption-post">
              <h3 class="title title--h3">If you’re not prepared to be wrong, you’ll never come up with anything
                original.</h3>
              <p>Here is one of the few effective keys to the design problem: the ability of the designer to recognize
                as many of the constraints as possible his willingness and enthusiasm for working within these
                constraints. The most profound technologies are those that disappear. They weave themselves into the
                fabric of everyday life until they are indistinguishable from it.</p>
              <blockquote class="block-quote">
                <p>The alternative to good design is always bad design. There is no such thing as no design.</p>
                <span class="block-quote__author">Adam Judge</span>
              </blockquote>
              <p>Above all, think of life as a prototype. We can conduct experiments, make discoveries, and change our
                perspectives. We can look for opportunities to turn processes into projects that have tangible outcomes.
              </p>
              <p>We can learn how to take joy in the things we create whether they take the form of a fleeting
                experience or an heirloom that will last for generations. We can learn that reward comes in creation and
                re-creation, no just in the consumption of the world around us. Active participation in the process of
                creation is our right and our privilege. We can learn to measure the success of our ideas not by our
                bank accounts by their impact on the world.</p>
            </div>
            <footer class="footer-post">
              <a class="footer-post__share" href="../index.htm"><i
                  class="font-icon icon-facebook"></i><span>Facebook</span></a>
              <a class="footer-post__share" href="../index-2.htm"><i
                  class="font-icon icon-twitter"></i><span>Twitter</span></a>
              <a class="footer-post__share" href="../index-1.htm"><i
                  class="font-icon icon-linkedin2"></i><span>Linkedin</span></a>
            </footer>
          </div>

          <div class="box-inner box-inner--rounded">
            <h2 class="title title--h3">Comments <span class="color--light">(3)</span></h2>

            <!-- Comment -->
            <div class="comment-box">
              <div class="comment-box__inner">
                <svg class="avatar avatar--60" viewbox="0 0 84 84">
                  <g class="avatar__hexagon">
                    <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
                  </g>
                </svg>
                <div class="comment-box__body">
                  <h5 class="comment-box__details"><span>Daniel Lewi</span><span class="comment-box__details-date">5 min
                      ago</span></h5>
                  <p>Very interesting and informative article on design. I learned a lot of new and interesting.</p>

                  <ul class="comment-box__footer">
                    <li><i class="font-icon icon-like-fill"></i> <span>80</span></li>
                    <li><i class="font-icon icon-reply"></i> <span>Reply</span></li>
                  </ul>
                </div>
              </div>

              <!-- Comment -->
              <div class="comment-box">
                <div class="comment-box__inner">
                  <svg class="avatar avatar--60" viewbox="0 0 84 84">
                    <g class="avatar__hexagon">
                      <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
                    </g>
                  </svg>
                  <div class="comment-box__body">
                    <h5 class="comment-box__details"><span>Jessica Miller</span><span
                        class="comment-box__details-date">1 min ago</span></h5>
                    <p>I agree, a very interesting article. Thank you very much! <img class="emoji"
                        src="assets/icons/emoji/emoji-nerd.svg" alt="nerd"></p>

                    <ul class="comment-box__footer">
                      <li><i class="font-icon icon-like-fill"></i> <span>35</span></li>
                      <li><i class="font-icon icon-reply"></i> <span>Reply</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- Comment -->
            <div class="comment-box">
              <div class="comment-box__inner">
                <svg class="avatar avatar--60" viewbox="0 0 84 84">
                  <g class="avatar__hexagon">
                    <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
                  </g>
                </svg>
                <div class="comment-box__body">
                  <h5 class="comment-box__details"><span>Henry William</span><span class="comment-box__details-date">15
                      min ago</span></h5>
                  <p>Thanks for the good article. Looking forward to new ones.</p>

                  <ul class="comment-box__footer">
                    <li><i class="font-icon icon-like-fill"></i> <span>15</span></li>
                    <li><i class="font-icon icon-reply"></i> <span>Reply</span></li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Comment form -->
            <ul class="social-auth">
              <li class="social-auth__item">Login with:</li>
              <li class="social-auth__item"><a class="social-auth__link" href="#"><i
                    class="font-icon icon-facebook"></i></a></li>
              <li class="social-auth__item"><a class="social-auth__link" href="#"><i
                    class="font-icon icon-twitter"></i></a></li>
              <li class="social-auth__item"><a class="social-auth__link" href="#"><i
                    class="font-icon icon-dribbble"></i></a></li>
              <li class="social-auth__item"><a class="social-auth__link" href="#"><i
                    class="font-icon icon-behance"></i></a></li>
            </ul>
            <form class="comment-form">
              <textarea id="commentForm" class="textarea textarea--white form-control" required="required"
                placeholder="Write a Comment..." rows="1"></textarea>
              <button type="submit" class="btn"><i class="font-icon icon-send"></i></button>
              <div class="dropdown dropup">
                <i class="font-icon icon-smile" id="dropdownEmoji" data-toggle="dropdown" aria-haspopup="true"></i>
                <div class="dropdown-menu dropdown-menu-center" aria-labelledby="dropdownEmoji">
                  <div class="emoji-wrap">
                    <img class="emoji" src="assets/icons/emoji/emoji-laughing.svg" title=":laughing:" alt="laughing">
                    <img class="emoji" src="assets/icons/emoji/emoji-happy-2.svg" title=":happy 2:" alt="happy 2">
                    <img class="emoji" src="assets/icons/emoji/emoji-crazy.svg" title=":crazy:" alt="crazy">
                    <img class="emoji" src="assets/icons/emoji/emoji-bad.svg" title=":bad:" alt="bad">
                    <img class="emoji" src="assets/icons/emoji/emoji-angry.svg" title=":angry:" alt="angry">
                    <img class="emoji" src="assets/icons/emoji/emoji-happy.svg" title="happy" alt="happy">
                    <img class="emoji" src="assets/icons/emoji/emoji-thinking.svg" title=":thinking:" alt="thinking">
                    <img class="emoji" src="assets/icons/emoji/emoji-sad.svg" title=":sad:" alt="sad">
                    <img class="emoji" src="assets/icons/emoji/emoji-pressure.svg" title=":pressure:" alt="pressure">
                    <img class="emoji" src="assets/icons/emoji/emoji-in-love.svg" title=":in love:" alt="in love">
                    <img class="emoji" src="assets/icons/emoji/emoji-nerd.svg" title=":laughing:" alt="nerd">
                    <img class="emoji" src="assets/icons/emoji/emoji-happy-3.svg" title=":happy 3:" alt="happy 3">
                    <img class="emoji" src="assets/icons/emoji/emoji-shocked.svg" title=":shocked:" alt="shocked">
                    <img class="emoji" src="assets/icons/emoji/emoji-wink.svg" title=":wink:" alt="wink">
                    <img class="emoji" src="assets/icons/emoji/emoji-sweating.svg" title=":sweating:" alt="sweating">
                    <img class="emoji" src="assets/icons/emoji/emoji-shocked-2.svg" title=":shocked 2:" alt="shocked 2">
                    <img class="emoji" src="assets/icons/emoji/emoji-shocked-3.svg" title=":shocked 3:" alt="shocked 3">
                    <img class="emoji" src="assets/icons/emoji/emoji-sad-2.svg" title=":sad 2:" alt="sad 2">
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>

        <!-- Footer -->
        <footer class="footer">© 2019 vCard</footer>
      </div>

    </div>
  </div>
</main>

<div class="back-to-top"></div>

<!-- SVG masks -->
<svg class="svg-defs">
  <clippath id="avatar-box">
    <path
      d="M1.85379 38.4859C2.9221 18.6653 18.6653 2.92275 38.4858 1.85453 56.0986.905299 77.2792 0 94 0c16.721 0 37.901.905299 55.514 1.85453 19.821 1.06822 35.564 16.81077 36.632 36.63137C187.095 56.0922 188 77.267 188 94c0 16.733-.905 37.908-1.854 55.514-1.068 19.821-16.811 35.563-36.632 36.631C131.901 187.095 110.721 188 94 188c-16.7208 0-37.9014-.905-55.5142-1.855-19.8205-1.068-35.5637-16.81-36.63201-36.631C.904831 131.908 0 110.733 0 94c0-16.733.904831-37.9078 1.85379-55.5141z">
    </path>
  </clippath>
  <clippath id="avatar-hexagon">
    <path
      d="M0 27.2891c0-4.6662 2.4889-8.976 6.52491-11.2986L31.308 1.72845c3.98-2.290382 8.8697-2.305446 12.8637-.03963l25.234 14.31558C73.4807 18.3162 76 22.6478 76 27.3426V56.684c0 4.6805-2.5041 9.0013-6.5597 11.3186L44.4317 82.2915c-3.9869 2.278-8.8765 2.278-12.8634 0L6.55974 68.0026C2.50414 65.6853 0 61.3645 0 56.684V27.2891z">
    </path>
  </clippath>
</svg>