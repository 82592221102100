import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { doc, Firestore, onSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dob: any = '';
  profileData: any;
  constructor(
    private firestore: Firestore,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.getProfileDesc();
  }

  getProfileDesc() {
    const collect$ = doc(this.firestore, 'kh_profileInfo/profile_info');
    onSnapshot(collect$, snap => {
      this.profileData = snap.data();
      this.dob = this.datepipe.transform(this.profileData?.dob.seconds * 1000, 'longDate')?.toString();
    });
  }


}
