<!-- CONTACT -->
<div id="contact-tab" class="tabcontent">
  <div class="pb-2">
    <h1 class="title title--h1 first-title title__separate">Contact</h1>
  </div>

  <div class="map" style="width: 100%; height: 350px;" id="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.0235917181594!2d103.86460941517504!3d13.348808590609213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311017191b06ee2b%3A0xafaef67ab893a82d!2ssFuture%20Apps!5e0!3m2!1sen!2skh!4v1644749404991!5m2!1sen!2skh"
      width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>

  <h2 class="title title--h3">Contact Form</h2>

  <form id="contact-form" class="contact-form" data-toggle="validator">
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <i class="font-icon icon-user"></i>
        <input type="text" class="input input__icon form-control" id="nameContact" name="nameContact"
          placeholder="Full name" required="required" autocomplete="on"
          oninvalid="setCustomValidity('Fill in the field')" onkeyup="setCustomValidity('')">
        <div class="help-block with-errors"></div>
      </div>
      <div class="form-group col-12 col-md-6">
        <i class="font-icon icon-envelope"></i>
        <input type="email" class="input input__icon form-control" id="emailContact" name="emailContact"
          placeholder="Email address" required="required" autocomplete="on"
          oninvalid="setCustomValidity('Email is incorrect')" onkeyup="setCustomValidity('')">
        <div class="help-block with-errors"></div>
      </div>
      <div class="form-group col-12 col-md-12">
        <textarea class="textarea form-control" id="messageContact" name="messageContact" placeholder="Your Message"
          rows="4" required="required" oninvalid="setCustomValidity('Fill in the field')"
          onkeyup="setCustomValidity('')"></textarea>
        <div class="help-block with-errors"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 order-2 order-md-1 text-center text-md-left">
        <div id="validator-contact" class="hidden"></div>
      </div>
      <div class="col-12 col-md-6 order-1 order-md-2 text-right">
        <button type="submit" class="btn"><i class="font-icon icon-send"></i> Send Message</button>
      </div>
    </div>
  </form>
</div>