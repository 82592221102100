<!-- Preloader -->
<div class="preloader">
  <div class="preloader__wrap">
    <div class="circle-pulse">
      <div class="circle-pulse__1"></div>
      <div class="circle-pulse__2"></div>
    </div>
    <div class="preloader__progress"><span></span></div>
  </div>
</div>

<main class="main">
  <div class="container gutter-top">
    <div class="row sticky-parent">

      <!-- Sidebar -->
      <aside class="col-12 col-md-12 col-xl-3">
        <div class="sidebar box shadow pb-0 sticky-column">
          <svg class="avatar avatar--180" viewbox="0 0 188 188">
            <g class="avatar__box">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <div class="text-center">
            <h3 class="title title--h3 sidebar__user-name"><span class="weight--500">{{profileData?.lastName}}</span>
              {{profileData?.firstName}}</h3>
            <div class="badge badge--light">{{profileData?.titile}}</div>

            <!-- Social -->
            <div class="social">
              <a class="social__link" href="{{profileData?.social_media[0]}}"><i
                  class="font-icon icon-facebook"></i></a>
              <a class="social__link" href="{{profileData?.social_media[1]}}"><i class="font-icon icon-twitter"></i></a>
              <a class="social__link" href="{{profileData?.social_media[2]}}"><i
                  class="font-icon icon-linkedin2"></i></a>
            </div>
          </div>

          <div class="sidebar__info box-inner box-inner--rounded">
            <ul class="contacts-block">
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Birthday">
                <i class="font-icon icon-calendar"></i>{{dob}}
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Address">
                <i class="font-icon icon-location"></i>{{profileData?.pob}}
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="E-mail">
                <a href="mailto:{{profileData?.email}}"><i
                    class="font-icon icon-envelope"></i>{{profileData?.email}}</a>
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Phone">
                <i class="font-icon icon-phone"></i>
                {{profileData?.phone[0]}} <br>
                {{profileData?.phone[1]}}
              </li>
              <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Messenger">
                <a href="{{profileData?.messenger}}"><i class="font-icon icon-facebook"></i>{{profileData?.firstName}}
                  {{profileData?.lastName}}</a>
              </li>
            </ul>
            <i class="fa-brands fa-facebook-messenger"></i>

            <a class="btn" href="{{profileData?.cv_download}}"><i class="font-icon icon-download"></i> Download CV</a>
          </div>
        </div>
      </aside>

      <!-- Content -->
      <div class="col-12 col-md-12 col-xl-9">
        <div class="box shadow pb-0">
          <!-- Menu -->
          <div class="circle-menu">
            <div class="hamburger">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
          <div class="inner-menu js-menu js-tabs">
            <ul class="nav">
              <li class="nav__item"><a class="active" href="#about-tab">About</a></li>
              <li class="nav__item"><a href="#resume-tab">Resume</a></li>
              <li class="nav__item"><a href="#portfolio-tab">Portfolio</a></li>
              <li class="nav__item"><a href="#blog-tab">Blog</a></li>
              <li class="nav__item"><a href="#contact-tab">Contact</a></li>
            </ul>
          </div>

          <div class="content" id="content">

            <router-outlet></router-outlet>

            <app-about></app-about>
            <app-resume></app-resume>
            <app-portfolio></app-portfolio>
            <app-blog></app-blog>
            <app-contact></app-contact>

          </div>
        </div>

        <!-- Footer -->
        <footer class="footer">© 2022 KHUT Lihong</footer>
      </div>
    </div>
  </div>
</main>


<div class="back-to-top"></div>

<!-- SVG masks -->
<svg class="svg-defs">
  <clippath id="avatar-box">
    <path
      d="M1.85379 38.4859C2.9221 18.6653 18.6653 2.92275 38.4858 1.85453 56.0986.905299 77.2792 0 94 0c16.721 0 37.901.905299 55.514 1.85453 19.821 1.06822 35.564 16.81077 36.632 36.63137C187.095 56.0922 188 77.267 188 94c0 16.733-.905 37.908-1.854 55.514-1.068 19.821-16.811 35.563-36.632 36.631C131.901 187.095 110.721 188 94 188c-16.7208 0-37.9014-.905-55.5142-1.855-19.8205-1.068-35.5637-16.81-36.63201-36.631C.904831 131.908 0 110.733 0 94c0-16.733.904831-37.9078 1.85379-55.5141z">
    </path>
  </clippath>
  <clippath id="avatar-hexagon">
    <path
      d="M0 27.2891c0-4.6662 2.4889-8.976 6.52491-11.2986L31.308 1.72845c3.98-2.290382 8.8697-2.305446 12.8637-.03963l25.234 14.31558C73.4807 18.3162 76 22.6478 76 27.3426V56.684c0 4.6805-2.5041 9.0013-6.5597 11.3186L44.4317 82.2915c-3.9869 2.278-8.8765 2.278-12.8634 0L6.55974 68.0026C2.50414 65.6853 0 61.3645 0 56.684V27.2891z">
    </path>
  </clippath>
</svg>