<!-- RESUME -->
<div id="resume-tab" class="tabcontent">
  <div class="pb-3">
    <h1 class="title title--h1 first-title title__separate">Resume</h1>
  </div>

  <!-- Experience -->
  <div class="pb-0">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h2 class="title title--h3"><img class="title-icon" src="assets/icons/icon-education.svg" alt="">
          Education</h2>
        <div class="timeline">
          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">University Of South-East Asia</h5>
            <span class="timeline__period">2011 — 2015</span>
            <p class="timeline__description">
              Bachelor of Information Technology <br>
              Siem Reap, Cambodia</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">10 January 1979 High School</h5>
            <span class="timeline__period">2007 — 2010</span>
            <p class="timeline__description">
              General Knowledge Khmer (High School) <br>
              Siem Reap, Cambodia
            </p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Preah Enkosa Secondary School</h5>
            <span class="timeline__period">2003 — 2007</span>
            <p class="timeline__description">
              General Knowledge Khmer(Secondary School) <br>
              Siem Reap, Cambodia
            </p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Koh Keo Primary School</h5>
            <span class="timeline__period">1996 — 2003</span>
            <p class="timeline__description">
              General Knowledge Khmer(Primary School) <br>
              Banteay Meanchey, Cambodia
            </p>
          </article>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <h2 class="title title--h3"><img class="title-icon" src="assets/icons/icon-experience.svg" alt="">
          Experience</h2>
        <div class="timeline">
          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Tech Develoment Team Leader</h5>
            <span class="timeline__period">July/2020 — Present</span>
            <p class="timeline__description">Nealika Enterprise Solutions Co.,LTD.</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Founder of sFuture Apps Co.,LTD.</h5>
            <span class="timeline__period">Aug/2019 – June/2020</span>
            <p class="timeline__description">sFuture Apps Co.,LTD.</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Web Design, Web & Mobile App Develop</h5>
            <span class="timeline__period">Jan/2018 – Apr/2019</span>
            <p class="timeline__description">Memoire Palace Resort & Spa</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Website Design & Develop</h5>
            <span class="timeline__period">May/2015 – Jan/2018</span>
            <p class="timeline__description">Nealika Enterprise Solutions Co.,LTD.</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Graphic Design</h5>
            <span class="timeline__period">May/2014 – May/2015</span>
            <p class="timeline__description">Angkor Cookies Souvenir Shop</p>
          </article>

          <!-- Item -->
          <article class="timeline__item">
            <h5 class="title title--h5 timeline__title">Graphic Design</h5>
            <span class="timeline__period">Feb/2011 – Apr/2014</span>
            <p class="timeline__description">Siem Reap Thmei Photoshop</p>
          </article>


        </div>
      </div>
    </div>
  </div>

  <!-- Skills -->
  <div class="box-inner box-inner--rounded">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h2 class="title title--h3">Design Skills</h2>
        <div class="box box__second">
          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Web & Mobile App Design </span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Web & Mobile App Design</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Graphic Design</span><span>80%</span></div>
            </div>
            <div class="progress-text"><span>Graphic Design</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Adobe XD</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Adobe XD</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Adobe Photoshop</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Adobe Photoshop</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Adobe Illustrator</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Adobe Illustrator</span></div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 mt-4 mt-lg-0">
        <h2 class="title title--h3">Coding Skills</h2>
        <div class="box box__second">


          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Flutter Framwork</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Flutter Framwork</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Angular & Typescript</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Angular & Typescript</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>NodeJs & Github</span><span>80%</span></div>
            </div>
            <div class="progress-text"><span>NodeJs & Github</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>WordPress</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>WordPress</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>Javascript</span><span>90%</span></div>
            </div>
            <div class="progress-text"><span>Javascript</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>HTML & CSS</span><span>100%</span></div>
            </div>
            <div class="progress-text"><span>HTML & CSS</span></div>
          </div>

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-text"><span>PHP & MySQL</span><span>80%</span></div>
            </div>
            <div class="progress-text"><span>PHP & MySQL</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>