import { Component, OnInit } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { collection, onSnapshot } from 'firebase/firestore';

declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {


  data: any;
  dataItems: any;
  constructor(private firestore: Firestore) { }

  ngOnInit(): void {
    this.getAboutDesc();
    this.getServiceItems();
  }

  getAboutDesc() {
    const collect$ = doc(this.firestore, 'kh_profileInfo/about_desc');
    onSnapshot(collect$, snap => {
      this.data = snap.data();
    });
  }

  getServiceItems() {
    const collect$ = doc(this.firestore, 'kh_profileInfo/about_serviceItems');
    onSnapshot(collect$, snap => {
      this.dataItems = snap.data()?.content;
    });
  }

}
