<div id="about-tab" class="tabcontent active">

  <!-- ABOUT -->
  <div class="pb-0 pb-sm-2">
    <h1 class="title title--h1 first-title title__separate">About Me</h1>
    <div [innerHTML]="data?.content"></div>
  </div>

  <!-- What -->
  <div class="box-inner pb-0" *ngIf="dataItems">
    <h2 class="title title--h3">What I'm Doing</h2>
    <div class="row">

      <!-- Case Item -->
      <div class="col-12 col-lg-6" *ngFor="let item of dataItems;">
        <div class="case-item box box__second">
          <img class="case-item__icon" [src]="item?.iconUrl" alt="">
          <div>
            <h3 class="title title--h5">{{item?.title}}</h3>
            <p class="case-item__caption">{{item?.desc}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Testimonials -->
  <div class="box-inner box-inner--white">
    <h2 class="title title--h3">Testimonials</h2>
    <div class="swiper-container js-carousel-review">
      <div class="swiper-wrapper">
        <!-- Item review -->
        <div class="swiper-slide review-item">
          <svg class="avatar avatar--80" viewbox="0 0 84 84">
            <g class="avatar__hexagon">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <h4 class="title title--h5">Daniel Lewis</h4>
          <p class="review-item__caption">Felecia was hired to create a corporate identity. We were very
            pleased with the work done.</p>
        </div>

        <!-- Item review -->
        <div class="swiper-slide review-item">
          <svg class="avatar avatar--80" viewbox="0 0 84 84">
            <g class="avatar__hexagon">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <h4 class="title title--h5">Jessica Miller</h4>
          <p class="review-item__caption">thanks to the skill of Felecia, we have a design that we can be
            proud of.</p>
        </div>

        <!-- Item review -->
        <div class="swiper-slide review-item">
          <svg class="avatar avatar--80" viewbox="0 0 84 84">
            <g class="avatar__hexagon">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <h4 class="title title--h5">Tanya Ruiz</h4>
          <p class="review-item__caption">Felecia was hired to create a corporate identity. We were very
            pleased with the work done.</p>
        </div>

        <!-- Item review -->
        <div class="swiper-slide review-item">
          <svg class="avatar avatar--80" viewbox="0 0 84 84">
            <g class="avatar__hexagon">
              <image xlink:href="assets/images/avatar-1.jpg" height="100%" width="100%"></image>
            </g>
          </svg>
          <h4 class="title title--h5">Thomas Castro</h4>
          <p class="review-item__caption">thanks to the skill of Felecia, we have a design that we can be
            proud of.</p>
        </div>
      </div>

      <div class="swiper-pagination"></div>
    </div>
  </div>

  <!-- Clients -->
  <div class="box-inner box-inner--rounded">
    <h2 class="title title--h3">Clients</h2>

    <div class="swiper-container js-carousel-clients">
      <div class="swiper-wrapper">
        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>

        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>

        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>

        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>

        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>

        <!-- Item client -->
        <div class="swiper-slide">
          <a href="#"><img src="assets/img/logo-partner.svg" alt="Logo"></a>
        </div>
      </div>

      <div class="swiper-pagination"></div>
    </div>
  </div>
</div>